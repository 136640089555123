import { MessageType } from '../../shared/notifications';
import { SystemLoading } from './enums';

export const SYSTEM_LOADING = 'SYSTEM_LOADING';
export const MESSAGE_ADD = 'MESSAGE_ADD';
export const MESSAGE_REMOVE = 'MESSAGE_REMOVE';
export const MESSAGES_CLEAR = 'MESSAGE_CLEAR';
export const CONFIG_SET = 'CONFIG_SET';
export const CONFIG_LOADED = 'CONFIG_LOADED';
export const LOGGED_IN = 'LOGGED_IN';
export const TOKEN_LOADED = 'TOKEN_LOADED';
export const TOKEN_UPDATED = 'TOKEN_UPDATED';
export const ERROR_SET = 'ERROR_SET';

// Action Definition
interface ISystemLoadingAction {
	type: typeof SYSTEM_LOADING;
	loading: SystemLoading;
	isLoading: boolean;
}

interface IMessageAddAction {
	type: typeof MESSAGE_ADD;
	created: Date;
	messageType: MessageType;
	message?: string;
	sticky?: boolean;
}

interface IMessageRemoveAction {
	type: typeof MESSAGE_REMOVE;
	index: number;
}

interface IMessagesClearAction {
	type: typeof MESSAGES_CLEAR;
}


interface IConfigSetAction {
	type: typeof CONFIG_SET;
	apiUrl: string;
	memberUrl: string;
	context?: microsoftTeams.Context;
}

interface IConfigLoadedAction {
	type: typeof CONFIG_LOADED;
	title: string | null;
	reversion: number | null;
	appClientId: string | null;
	memberTenantPath: string | null;
}

interface ILoggedInAction {
	type: typeof LOGGED_IN;
	apiKey: string | null;
}

interface ITokenLoadedAction {
	type: typeof TOKEN_LOADED;
	token: string;
	groupId: string;
}

interface ITokenUpdatedAction {
	type: typeof TOKEN_UPDATED;
	token: string;
}

interface IErrorSetAction {
	type: typeof ERROR_SET;
	error?: string;
	errorStatus?: number;
}

// Union Action Types
export type ISystemActionTypes =
	| ISystemLoadingAction
	| IMessageAddAction
	| IMessageRemoveAction
	| IMessagesClearAction
	| IConfigSetAction
	| IConfigLoadedAction
	| ILoggedInAction
	| ITokenLoadedAction
	| ITokenUpdatedAction
	| IErrorSetAction;
